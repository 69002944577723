import * as React from 'react';
import { navigate } from 'gatsby';
import { Box, Text, useBreakpointValue } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';

const StoreButton = () => (
  <Text
    position="absolute"
    bottom="12%"
    right="0%"
    as="button"
    bg="blue"
    px={{ base: 2, md: 4, xl: 8 }}
    py={2}
    color="white"
    mr={{ base: 2, md: 8 }}
    fontWeight="bold"
    fontSize={{ base: 'md', md: '5xl', xl: '6xl' }}
    _hover={{
      cursor: 'pointer',
    }}
    onClick={() => navigate('/store')}
  >
    Store
  </Text>
);

export default function KeyVisualWatchDeco() {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box textAlign="center" position="relative">
      <StaticImage alt="" src="../images/kv_wd.png" />
      <Text
        w="full"
        color="blue"
        fontSize={{ base: 'md', md: '4xl', xl: '5xl' }}
        fontWeight="bold"
        position="absolute"
        top="10%"
        left="50%"
        transform="translate(-50%, -50%)"
      >
        {`<Choker Joker>`}
        腕時計専門のセレクトショップです
      </Text>
      <StoreButton />
    </Box>
  );
}
