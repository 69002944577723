import * as React from 'react';
import {
  Flex,
  Stack,
  Box,
  Text,
  useBreakpointValue,
  Heading,
  chakra,
} from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import { maxW } from '../assets/config';
import KeyVisualWatchDeco from '../components/KeyVisualWatchDeco';
import Layout from '../components/layout';
import PickUpWatchListing from '../components/PickUpWatchListing';
import Seo from '../components/seo';
import JsonLd from '../components/JsonLd';
import NoIndex from '../components/NoIndex';

const SectionContent1 = () => (
  <u>
    いい時計⇨高い時計？
    <br />
    アナタにとっていい時計とは、
    <br />
    アナタに「似合う時計」です
  </u>
);

const SectionContent2 = () => (
  <u>
    CJのチョーカーにマッチする
    <br />
    腕時計をセレクトチョイス
  </u>
);

const SectionContent3 = () => (
  <u>
    電池交換はとっても簡単(^o^)/
    <br />
    より長くお使い頂くために〜
  </u>
);

const WhatsCjPage = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Layout full={true}>
      <Seo
        title="WatchDeco"
        description="ChokerJokerが手がける時計ブランドの商品です。"
      />
      <JsonLd
        title="WatchDeco"
        description="ChokerJokerが手がける時計ブランドの商品です。"
      />
      <NoIndex />
      <KeyVisualWatchDeco />
      <Stack spacing={8} mt={8} px={4} maxW={maxW} mx="auto">
        <section>
          {isMobile ? (
            <>
              <Box textAlign="center">
                <StaticImage
                  src="../images/wd-img1.png"
                  alt="watch-deco1"
                  width={300}
                />
              </Box>
              <Flex justify="center" alignItems="center" mt={4}>
                <Text fontWeight="bold" fontSize="3xl">
                  <SectionContent1 />
                </Text>
              </Flex>
            </>
          ) : (
            <Flex>
              <Flex w="50%" justify="center" alignItems="center">
                <Text fontWeight="bold" fontSize="4xl">
                  <SectionContent1 />
                </Text>
              </Flex>
              <Box w="50%">
                <StaticImage src="../images/wd-img1.png" alt="watch-deco1" />
              </Box>
            </Flex>
          )}
        </section>
        <section>
          {isMobile ? (
            <>
              <Box textAlign="center">
                <StaticImage
                  src="../images/wd-img2.png"
                  alt="watch-deco2"
                  width={300}
                />
              </Box>
              <Flex justify="center" alignItems="center" mt={4}>
                <Text fontWeight="bold" fontSize="3xl">
                  <SectionContent2 />
                </Text>
              </Flex>
            </>
          ) : (
            <Flex>
              <Box w="50%">
                <StaticImage src="../images/wd-img2.png" alt="watch-deco2" />
              </Box>
              <Flex w="50%" justify="center" alignItems="center">
                <Text fontWeight="bold" fontSize="4xl">
                  <SectionContent2 />
                </Text>
              </Flex>
            </Flex>
          )}
        </section>
        <section>
          {isMobile ? (
            <>
              <Box textAlign="center">
                <StaticImage
                  src="../images/wd-img3.png"
                  alt="watch-deco3"
                  width={300}
                />
              </Box>
              <Flex justify="center" alignItems="center" mt={4}>
                <Text fontWeight="bold" fontSize="3xl">
                  <SectionContent3 />
                </Text>
              </Flex>
            </>
          ) : (
            <Flex>
              <Flex w="50%" justify="center" alignItems="center">
                <Text fontWeight="bold" fontSize="4xl">
                  <SectionContent3 />
                </Text>
              </Flex>
              <Box w="50%">
                <StaticImage src="../images/wd-img3.png" alt="watch-deco3" />
              </Box>
            </Flex>
          )}
        </section>
        <chakra.hr h={1} bg="black" w="full" />
        <Heading my={4} textAlign="center">
          <u>〜PICK UP〜</u>
        </Heading>
        <PickUpWatchListing />
      </Stack>
    </Layout>
  );
};

export default WhatsCjPage;
