import { Flex, Spacer, Text, useBreakpointValue } from '@chakra-ui/react';
import { graphql, navigate } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { formatPrice } from '../utils/format-price';

type ProductPreviewCardProps = {
  image: any;
  title: string | undefined;
  price: string | undefined;
  currency: string | undefined;
  handle: string | undefined;
};

const ProductPreviewCard = ({
  image,
  title,
  price,
  currency = 'JPY',
  handle,
}: ProductPreviewCardProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Flex
      direction="column"
      justify="space-between"
      w="100%"
      p={2}
      _hover={{
        cursor: 'pointer',
      }}
      onClick={() => navigate(`/product/${handle}`)}
    >
      <GatsbyImage
        alt={image?.altText ?? title}
        image={image?.gatsbyImageData ?? null}
      />
      <Text mt={4} fontWeight="bold" fontSize={isMobile ? '3xl' : '4xl'}>
        {title}
      </Text>
      <Spacer />
      <Text my={4} fontSize={isMobile ? '2xl' : '3xl'} textAlign="center">
        {formatPrice(currency, Number(price))}
      </Text>
    </Flex>
  );
};

export const query = graphql`
  fragment ProductCard on ShopifyProduct {
    ...ShopifyProductFields
    ...ProductTileFields
  }
`;

export default ProductPreviewCard;
