export const formatPrice = (
  currency: string | undefined,
  value: number | undefined
) => {
  if (currency && value) {
    return Intl.NumberFormat('ja-JP', {
      currency,
      style: 'currency',
    }).format(value);
  } else {
    return 0;
  }
};
