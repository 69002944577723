import * as React from 'react';
import { Helmet } from 'react-helmet';

function NoIndex() {
  return (
    <Helmet
      meta={[
        {
          name: `robots`,
          content: `noindex, nofollow, noimageindex`,
        },
      ]}
    />
  );
}

export default NoIndex;
