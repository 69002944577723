import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Grid, GridItem, useBreakpointValue } from '@chakra-ui/react';
import ProductPreviewCard from './ProductPreviewCard';

const query = graphql`
  query ShopifyPickUpWatchProducts {
    pickUpWatch: allShopifyCollection(
      filter: { id: { eq: "0162f693-5e05-517f-aa3d-071124328442" } }
    ) {
      nodes {
        title
        products {
          ...ShopifyProductFields
          ...ProductTileFields
        }
      }
    }
  }
`;

const PickUpWatchListing = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const queriedProducts =
    useStaticQuery<GatsbyTypes.ShopifyPickUpWatchProductsQuery>(query);
  const products = queriedProducts.pickUpWatch.nodes[0]?.products;

  return (
    <Grid templateColumns={isMobile ? 'repeat(2, 1fr)' : 'repeat(4, 1fr)'}>
      {products?.map(p => (
        <GridItem w="100%" key={p?.shopifyId}>
          <ProductPreviewCard
            image={p?.images?.[0]}
            title={p?.title}
            price={p?.priceRangeV2?.minVariantPrice?.amount}
            currency={p?.priceRangeV2?.minVariantPrice?.currencyCode}
            handle={p?.handle}
          />
        </GridItem>
      ))}
    </Grid>
  );
};

export default PickUpWatchListing;
